import axios from "axios";
import { toast } from "react-toastify";

import { API_BASE } from "./constants";

const authHeader = () => {
  const token = localStorage.getItem("towsontoken");
  if (token) {
    return { Authorization: "Bearer " + token };
  }
  return {};
};

export default (history = null) => {
  const axiosInstance = axios.create({ baseURL: API_BASE, headers: authHeader() });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response.status === 502 ||
        error.response.status === 401
      ) {
        const { message } = error.response.data;
        localStorage.clear();
        if (message) {
          toast.error(message, { toastId: "errorIf" });
        } else {
          toast.error("Logging Out!!", { toastId: "errorElse" });
        }
        // setTimeout(() => {
        //   localStorage.clear();
        //   if (history) {
        //     history.push("/login");
        //   } else {
        //     window.location = "/login";
        //   }
        // }, 1000);
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
