import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/AuthSlice";
import usersReducer from "../features/UsersSlice";

const persistMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const { token } = store.getState().auth;
  localStorage.setItem("towsontoken", token);
  return result;
};

export const store = configureStore({
  reducer: { auth: authReducer, users: usersReducer },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(persistMiddleware),
});
