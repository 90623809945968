import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

const ListStudent = lazy(() =>
  import("../pages/student/studentManagement/List")
);
const AddStudent = lazy(() => import("../pages/student/studentManagement/Add"));
const ViewStudent = lazy(() =>
  import("../pages/student/studentManagement/View")
);
const EditStudent = lazy(() =>
  import("../pages/student/studentManagement/Edit")
);

const ChangePassword = lazy(() => import("../pages/auth/ChangePassword"));

//Creator........................................................................................
const ListTeacher = lazy(() => import("../pages/teacher/teacherManagement/List"));
const ViewTeacher = lazy(() => import("../pages/teacher/teacherManagement/View"));
const EditTeacher = lazy(() => import("../pages/teacher/teacherManagement/Edit"));
const AddTeacher = lazy(() => import("../pages/teacher/teacherManagement/Add"));

//Language........................................................................................
const ListLanguage = lazy(() => import("../pages/metadataManagement/language/list"));
const AddLanguage = lazy(() => import("../pages/metadataManagement/language/Edit"));

//Institution........................................................................................
const ListInstitution = lazy(() => import("../pages/metadataManagement/institution/list"));
const AddInstitution = lazy(() => import("../pages/metadataManagement/institution/Edit"));
 
//Course Level........................................................................................
const ListCourseLevel = lazy(() => import("../pages/metadataManagement/courseLevel/list"));
const AddCourseLevel = lazy(() => import("../pages/metadataManagement/courseLevel/Edit"));

//CMS............................................................ ............................
const CmsPage = lazy(() => import("../pages/content/cms/CmsPage"));
const ViewCms = lazy(() => import("../pages/content/cms/ViewCms"));
const AddEditCms = lazy(() => import("../pages/content/cms/AddEditCms"));

//MyAccount........................................................................................
const MyAccount = lazy(() => import("../pages/myAccount/MyAccount"));

// questionnaire
const TaskAndQuestionnaireList = lazy(() => import("../pages/questionnaireManagement/List"));
const EditTaskAndQuestionnaire = lazy(() => import("../pages/questionnaireManagement/Edit"));

// task 
const TaskList = lazy(() => import("../pages/taskManagement/List"));
const EditAddTask = lazy(() => import("../pages/taskManagement/Edit"));


// courses
const CourseList = lazy(() => import("../pages/courses/list"));
const CourseEdit = lazy(() => import("../pages/courses/Edit"));



const PrivateLayout = lazy(() => import("../layouts/private"));
const Page404 = lazy(() => import("../pages/error/Page404"));
const DashboardPage = lazy(() => import("../pages/dashboard/DashboardPage"));
const LoadingFallback = <div>Loading...</div>;

const makeLazyComponent = (importFunc) => (
  <Suspense fallback={LoadingFallback}>{importFunc()}</Suspense>
);

const MainRouting = () => {
  const routes = [
    {
      element: <PrivateLayout />,
      errorElement: <Page404 />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        {
          path: "dashboard",
          element: makeLazyComponent(() => <DashboardPage />),
        },

        {
          path: "reset-password",
          element: makeLazyComponent(() => <ChangePassword />),
        },
        {
          path: "profile",
          element: makeLazyComponent(() => <MyAccount />),
        },
        {
          path: "user-management",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <ListTeacher />),
            },
            { path: "add", element: makeLazyComponent(() => <AddTeacher />) },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewTeacher />),
            },
            {
              path: "edit/:id",
              element: makeLazyComponent(() => <EditTeacher />),
            },
          ],
        },
        {
          path: "cms",
          children: [
            {
              index: true,
              element: makeLazyComponent(() => <CmsPage />),
            },
            {
              path: "add",
              element: makeLazyComponent(() => <AddEditCms />),
            },

            {
              path: "edit/:id",
              element: makeLazyComponent(() => <AddEditCms />),
            },
            {
              path: "view/:id",
              element: makeLazyComponent(() => <ViewCms />),
            },
          ],
        },
        {
          path: "courses",
          children: [
            { index: true, element: makeLazyComponent(() => <CourseList />) },
            { path: "add", element: makeLazyComponent(() => <CourseEdit />) },
            { path: "edit/:id", element: makeLazyComponent(() => <CourseEdit />) }
          ]
        }


      ],
    },
    { path: "*", element: <Navigate to="/dashboard" /> },
  ];

  return useRoutes(routes);
};



export default MainRouting;
